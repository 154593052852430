import { Tests } from "./tests/tests";
import { TABS } from "../../../../static/constants/constants";
import { CardTabs } from "../../components/cardTabs/cardTabs";
import { useCardTabs } from "../../hooks/useCardTabs";
import { Card } from "../../card/Card";

export const RightWidget = props => {
  const { run } = props;
  const { activeTabState, getTabs } = useCardTabs(run);

  const tabs = getTabs();
  const activeTab = activeTabState[0];

  return (
    <Card fillWidth fillHeight tabIndex={0}>
      <CardTabs tabs={tabs} activeTabState={activeTabState} />
      {activeTab === TABS.TEST_SUITES ? <Tests {...props} /> : null}
    </Card>
  );
};
