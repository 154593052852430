import { useState, useEffect } from "react";
import { GET_VERSION_URL } from "../../../static/constants/constants";

export const useDownloads = () => {
  const [downloadVersion, setDownloadVersion] = useState("");

  const fetchVersion = async () => {
    let res = await fetch(GET_VERSION_URL.path, { method: "GET" });
    let text = await res.text();
    setDownloadVersion(text);
  };

  useEffect(() => {
    fetchVersion();
  }, []);

  return {
    downloadVersion,
  };
};
