import React from "react";
import { CustomLink } from "../../components/Link/StyledLink";
import {
  APP_DOWNLOAD_DMG,
  APP_DOWNLOAD_MSI,
  APP_DOCUMENTATION_LINK,
  APP_DOWNLOAD_APP_IMAGE,
} from "../../static/constants/constants";
import {
  OSCard,
  StyledTitle,
  IconContainer,
  DownloadButton,
  OSCardContainer,
  StyledContainer,
  VerticalContainer,
  StyledProductCard,
  StyledSecondaryText,
} from "./styles";
import { Button } from "../../components";
import { useTranslation } from "react-i18next";
import { useDownloads } from "./hooks/useDownloads";
import { useApp } from "../../context/app/app.provider";
import { ReactComponent as AppleLogo } from "../../static/assets/icon-apple.svg";
import { ReactComponent as LinuxLogo } from "../../static/assets/icon-linux.svg";
import { ReactComponent as FlareIcon } from "../../static/assets/icon-flare.svg";
import { ReactComponent as WindowsLogo } from "../../static/assets/icon-windows.svg";

export const Downloads = () => {
  const i18n = useTranslation();
  const { isNotDesktop } = useApp();
  const { downloadVersion } = useDownloads();

  return (
    <StyledContainer>
      <StyledTitle>{i18n.t("Downloads")}</StyledTitle>
      <StyledProductCard>
        <FlareIcon />
        <VerticalContainer>
          <StyledTitle>{i18n.t("TestEvolve Flare")}</StyledTitle>
          <StyledSecondaryText>{i18n.t("Latest version:")}</StyledSecondaryText>
          <StyledTitle>
            {i18n.t("Version:")} {downloadVersion}
          </StyledTitle>
        </VerticalContainer>
      </StyledProductCard>
      <CustomLink accent small={true} to={APP_DOCUMENTATION_LINK}>
        <Button large onClick={() => {}}>
          {i18n.t("View Documentation")}
        </Button>
      </CustomLink>
      <br />
      <StyledTitle>{i18n.t("Choose your OS:")}</StyledTitle>
      <OSCardContainer isNotDesktop={isNotDesktop}>
        <OSCard>
          <StyledTitle>{i18n.t("Mac OS")}</StyledTitle>
          <StyledSecondaryText>{i18n.t(".dmg")}</StyledSecondaryText>
          <IconContainer>
            <AppleLogo />
          </IconContainer>
          <CustomLink
            button
            accent
            small
            to={APP_DOWNLOAD_DMG(downloadVersion)}
          >
            <DownloadButton>{i18n.t("Download")}</DownloadButton>
          </CustomLink>
        </OSCard>
        <OSCard>
          <StyledTitle>{i18n.t("Windows")}</StyledTitle>
          <StyledSecondaryText>{i18n.t(".exe")}</StyledSecondaryText>
          <WindowsLogo />
          <CustomLink
            button
            accent
            small
            to={APP_DOWNLOAD_MSI(downloadVersion)}
          >
            <DownloadButton>{i18n.t("Download")}</DownloadButton>
          </CustomLink>
        </OSCard>
        <OSCard>
          <StyledTitle>{i18n.t("Linux")}</StyledTitle>
          <StyledSecondaryText>{i18n.t(".AppImage")}</StyledSecondaryText>
          <LinuxLogo />
          <CustomLink
            button
            accent
            small
            to={APP_DOWNLOAD_APP_IMAGE(downloadVersion)}
          >
            <DownloadButton>{i18n.t("Download")}</DownloadButton>
          </CustomLink>
        </OSCard>
      </OSCardContainer>

      <br />
    </StyledContainer>
  );
};
