import styled from "styled-components/macro";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  gap: ${({ theme }) => theme.spacing.paddingLG};

  width: 100%;
`;

export const StyledTitle = styled.h1``;

export const StyledSecondaryText = styled.p``;

export const StyledProductCard = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colours.altForeground};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: fit-content;
  gap: ${({ theme }) => theme.spacing.padding};

  svg {
    height: 96px;
  }
`;

export const OSCardContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingLG};
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
`;

export const OSCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.altForeground};
  padding: ${({ theme }) => theme.spacing.paddingLG};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: 280px;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const DownloadButton = styled.button`
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.paddingLG};
  background-color: ${({ theme }) => theme.colours.haloAccent};
  border-radius: ${({ theme }) => theme.borders.radius};
  cursor: pointer;
  box-shadow: none;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colours.white};
`;

export const IconContainer = styled.div`
  height: 96px;
  color: ${({ theme }) => theme.colours.mainText};
  svg {
    height: 100%;
  }
`;
